const {useEffect} = require('react');
const {Time} = require('./MomentTime');
const {DayMapping} = require('./constants');
const {useTheme} = require("@emotion/react");
const {useMediaQuery} = require("@mui/material");

module.exports = {
    getDateTime: date => {
        return Time.University(date).format('MMMM D, YYYY, h:mm A');
    },

    // Redirects the user to old portal
    stopPreview: href => {
        // delete the preview cookie
        document.cookie =
            'portal-preview=; Path=/; Domain=.odu.edu; Expires=Thu, 01 Jan 1970 23:59:59 GMT';
        // navigate to old portal
        window.location.href = href;
    },

    //Θ(N) where N is the number of space separated tokens in a string
    //clean up the id string by converting to lower case, remove any special chars except '_' and trim
    cleanUpID: id => {
        if (!id) return '';

        return (
            id
                ?.toLocaleLowerCase()
                ?.trim()
                ?.replace(/[^a-zA-Z0-9_ ]/g, '')
                ?.split(/\s/g)
                ?.map((token, index) =>
                    index
                        ? (token?.[0]?.toLocaleUpperCase() ?? '') +
                          (token?.slice(1) ?? '')
                        : token
                )
                ?.join('') ?? ''
        );
    },

    //Θ(NxM) where N is the number of nested objects and M is the number of attributes in each nested object
    //Deep copies an object to avoid problems with referenced data
    deepCloneObject: object => {
        return Object.keys(object).reduce((clone, current) => {
            clone[current] =
                object[current].constructor === Object
                    ? module.exports.deepCloneObject(object[current])
                    : object[current];
            return clone;
        }, {});
    },

    // Convert days code (ex. MWF) to full name (ex. Monday, Wednesday and Friday)
    convertDayCodesToFullNames: dayCodes => {
        const fullNames = Array.from(dayCodes).map(
            code => DayMapping[code] || code
        );

        if (fullNames.length > 1) {
            const lastDay = fullNames.pop();
            return `${fullNames.join(', ')} and ${lastDay}`;
        }

        return fullNames.join(', ');
    },

    isHTML: content => /<\/?[a-z][\s\S]*>/i.test(content),
    //Swaps the array elements at index1 & index2
    swap: (array, index1, index2) => {
        let temp = array[index1];
        array[index1] = array[index2];
        array[index2] = temp;

        return array;
    },

    //θ(N X M) where N is the number of words and M is the number of characters in each word
    //Converts the string to title case
    toTitleCase: str => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    },

    /**
     * A custom hook that intercepts window.open when there are unsaved changes.
     *
     * @param {boolean} isDirty - Flag indicating if there are unsaved changes.
     * @param {Function} setOpenUnsavedChangesModal - Function to toggle the unsaved changes modal.
     * @param {string|null} pendingExternalUrl - The URL saved from an intercepted external navigation.
     * @param {Function} setPendingExternalUrl - Function to set or clear the pending external URL.
     */
    useInterceptWindowOpen: ({
        isDirty,
        setOpenUnsavedChangesModal,
        pendingExternalUrl,
        setPendingExternalUrl
    }) => {
        // Effect to override window.open when isDirty changes.
        useEffect(() => {
            // Save the original window.open so we can restore it later.
            const originalWindowOpen = window.open;

            // Override window.open.
            window.open = (url, target, features) => {
                if (isDirty) {
                    // Save the URL and open the unsaved changes modal.
                    setPendingExternalUrl(url);
                    setOpenUnsavedChangesModal(true);
                    return null; // Prevent the navigation.
                }
                // If not dirty, call the original window.open.
                return originalWindowOpen.call(window, url, target, features);
            };

            // Cleanup: restore the original window.open.
            return () => {
                window.open = originalWindowOpen;
            };
        }, [isDirty, setOpenUnsavedChangesModal, setPendingExternalUrl]);

        // Effect to automatically open the pending external URL when isDirty becomes false.
        useEffect(() => {
            if (!isDirty && pendingExternalUrl) {
                // Open the external URL in a new tab.
                window.open(pendingExternalUrl, '_blank');
                // Clear the pending URL once opened.
                setPendingExternalUrl(null);
            }
        }, [isDirty, pendingExternalUrl, setPendingExternalUrl]);
    },

    /**
     * React Hook to check if the display is currently "small" based on MUI breakpoints.
     * Mobile browsers and narrow browser windows on desktop are small.
     * Used to render UI differently. Responsive - include directly in component rendering.
     * @returns {boolean} - true if client screen is narrow, false otherwise
     */
    useSmallSize: () => {
        const theme = useTheme();
        return useMediaQuery(theme.breakpoints.down('md'))
    },
};
