export const impersonation = 'impersonation';
export const myCourses = 'my/courses';
export const myCoursesTaking = 'my/courses/taking';
export const myCoursesTeaching = 'my/courses/teaching';
export const courses = 'courses';
export const courseMapping = 'courses/mapping';
export const tutoring = 'tutoring';
export const myTransferHistory = 'my/transferhistory';
export const statusAlerts = 'my/statusAlerts';
export const hideStatusAlerts = 'my/statusAlerts/hide';
export const unhideStatusAlerts = 'my/statusAlerts/unhide';
export const checklists = 'my/checklists';
export const checklistDetails = code => `my/checklists/${code}`;
export const safety = 'safety';
export const myPreferences = 'my/preferences';
export const myAdvisingTeam = 'my/advisingTeam';
export const placesAndHours = 'placesandhours';
export const mySchedule = 'my/schedule';
export const news = 'news';
export const myBookmarks = 'my/bookmarks';
export const cct = 'cct';
export const cctClass = 'cct/class';
export const adhoc = 'cct/adhoc';
export const cctSync = 'cct/sync';
export const announcements = 'announcements';
export const googleDrive = {
    create: `googleDrive/`,
    load: ({crn, termCode}) => `googleDrive/${crn}/${termCode}`,
    delete: ({crn, termCode}) => `googleDrive/${crn}/${termCode}`
};
export const gisDrive = {
    create: `gisDrive/`,
    get: ({crn, termCode}) => `gisDrive/${crn}/${termCode}`
};
export const systemStatus = 'systemStatus';
export const myContacts = 'my/contacts';
export const myInfo = 'my/info';
export const notifications = 'notifications';
export const dismissNotification = id => `notifications/hide/${id}`;
export const myDining = 'my/dining';
export const locations = {
    locations: `locations`,
    getLocation: ({catergoryId, placeId}) =>
        `locations/${catergoryId}/${placeId}`,
    deleteLocation: ({catergoryId, placeId}) =>
        `locations/${catergoryId}/${placeId}`
};
export const terms = 'terms';
export const profilePhoto = 'my/profile/photo';
export const teamsDrive = {
    create: type => `teamsDrive/${type}`,
    get: ({crn, termCode, name}) =>
        `teamsDrive?name=${name}&crn=${crn}&termCode=${termCode}`,
    delete: ({crn, termCode, name}) =>
        `teamsDrive?name=${name}&crn=${crn}&termCode=${termCode}`
};
export const myRegistration = 'my/registration';
export const enableGuest = 'cct/enable/guest';
export const joinBeforeHostUpdate = 'cct/host/join';
export const addAltHostUpdate = 'cct/host/add';
export const myBalances = 'my/balances';
export const errors = {
    create: 'errors',
    getAll: 'errors',
    getAllByMidas: midas => `errors/${midas}`
};
export const lti = 'internal/lti';
export const ltiCourse = 'cct/course';
export const feedback = {
    feedback: `feedback`,
    get: ({midas, timestamp}) => `feedback/${midas}/${timestamp}`,
    update: ({midas, timestamp}) => `feedback/${midas}/${timestamp}`
};

export const cognitoErrors = 'errors/cognito';
export const myWaitlist = 'my/waitlist';
export const infoTips = 'infoTips';

export const sam = {
    allDepartments: 'sam/department',
    allAdvisors: 'sam/advisor',
    unassignedStudents: departmentCode =>
        `sam/students/unassigned/${departmentCode}`,
    assignedStudents: (pidm, type) => `sam/advisor/students/${pidm}/${type}`,
    unassignStudents: 'sam/students/unassign',
    assignStudent: 'sam/students/assign',
    differentMajor: departmentCode =>
        `sam/students/differentMajor/${departmentCode}`,
    missingAdvisorCode: 'sam/students/missingAdvisorCode'
};

export const myProgram = 'my/program';

export const users = (params = {}) => {
    const query = new URLSearchParams(params).toString();
    return `users${query ? `?${query}` : ''}`;
};
export const roleBasedUsers = role => `users/${role}`;
export const ssa = {
    users: (termCode, crn) => {
        return `ssa/access/users/${termCode}/${crn}`;
    },
    grant: () => {
        return `ssa/access`;
    },
    revoke: () => `ssa/access/revoke`,
    access: ({termCode, crn}) => `ssa/access/${termCode}/${crn}`,
    students: ({termCode, crn}) => `ssa/students/${termCode}/${crn}`,
    update: () => `ssa/activity`,
    status: ({termCode, crn}) => `ssa/job/status/${termCode}/${crn}`,
    getJobs: ({termCode, crn, startDate, endDate}) =>
        `ssa/job/${termCode}/${crn}?start=${startDate}&end=${endDate}`,
    getJobDetails: ({jobId, termCode, crn}) =>
        `ssa/job/report/${jobId}/${termCode}/${crn}`,
    retry: () => `ssa/job/retry`
};
