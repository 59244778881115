import MeetingActions from "./MeetingActions";
import moment from 'moment';
import MeetingTitle from "./MeetingTitle";
import { Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from "../../../../../common/PortalTooltip";
import { Time } from "../../../../../common/MomentTime";
import PortalTime from "../../../../../common/PortalTime";
import { isMeetingReadyToStart, startMeeting } from "../../../utils";
import React from "react";
import {useSmallSize} from "../../../../../common/utils";

//Θ(1) returns the columns for meetings
export const getColumns = (props, isCurrentMeetings) => {
    const timeFormat = "h:mm A"
    return [
        {field: 'topic', headerName: 'Title', flex: 1.5,
            renderCell: params => {
                //TODO: render meeting recording for past meetings
                return <MeetingTitle row = {params.row} openSnackbar = {props.openSnackbar} getShortTopic = {props.getShortTopic} />
            }
        },
        {field: 'start_time', headerName: 'Date/Time', flex: 2,
            renderCell: params => {
                const meeting_date = Time.University(params.value).startOf('day');
                const today = Time.University().startOf('day');

                // 0 if meeting is today, 1 if meeting is tomorrow, -1 if meeting is yesterday, ...
                const daysUntilMeeting = meeting_date.diff(today, 'day')

                const startTime = Time.University(params.value);
                const endTime = Time.University(params.value).add(params.row.duration);
                const currentTime = Time.University();

                const timeBlock = (
                    <React.Fragment>
                    <PortalTime
                        className="myOdu__small"
                        time={Time.University(params.value).format(timeFormat)}
                        title={Time.University(params.value).format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                    />
                    &nbsp; {' - '} &nbsp;
                    <PortalTime
                        className="myOdu__small"
                        time={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat)}
                        title={Time.University(params.value).add(params.row.duration, 'minutes').format(timeFormat) + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'}
                    />
                    </React.Fragment>
                )

                if(meeting_date.isSame(today, 'day')) {
                    // meeting is today -- format: Today, 10:00 AM - 11:00 AM
                    return <Grid container alignItems='center' gap={0.2} wrap="nowrap">
                        {
                            // render meeting start icon/button if it is the right time
                            (isMeetingReadyToStart(params.row) ||
                            ( moment.duration(startTime.diff(currentTime)).asMinutes() > 0 && moment.duration(endTime.diff(currentTime)).asMinutes() <= 0 )) &&
                            isCurrentMeetings && params.row.start_url &&
                            <Grid item>
                                <PortalTooltip id={params.row.topic + '_start_meeting'} title={props.course.isTeaching ? "Start meeting" : "Launch meeting"}>
                                    <IconButton color="inherit" aria-label="start_meeting" onClick={() => {
                                        startMeeting(props.email, params.row, props.course)
                                    }} className="myOdu__iconButton myOdu__urgent">
                                        <FontAwesomeIcon icon={faAlarmClock} fixedWidth />
                                    </IconButton>
                                </PortalTooltip>
                            </Grid>
                        }
                        <Grid item container direction={'row'}>
                            {'Today, '} &nbsp;
                            { timeBlock }
                        </Grid>
                    </Grid>
                }
                else if (daysUntilMeeting === 1) {
                    // meeting is tomorrow -- format: Tomorrow, 3:00 PM - 4:15 PM
                    return <Grid container direction={'row'}>
                        {'Tomorrow, '} &nbsp;
                        { timeBlock }
                    </Grid>
                }

                // else if(meeting_date.startOf('week').diff(today.startOf('week'), 'weeks') <= 1) {
                if(daysUntilMeeting > 1 && daysUntilMeeting <= 7) {
                    // meeting is in last week, this week, or next week -- format: Monday, Jan 4, 10:00 AM - 11:00 AM
                    return <Grid container direction={'row'}>
                        {Time.University(params.value).format('dddd, ')}
                        &nbsp;
                        { timeBlock }
                    </Grid>
                }
                // meeting is more than two weeks away -- Format: January 1, 10:00 AM - 11:00 AM
                return <Grid container direction={'row'}>
                    {useSmallSize() && Time.University(params.value).format('MMM D, ')}
                    {!useSmallSize() && Time.University(params.value).format('MMMM D, ')}
                    &nbsp;
                    { timeBlock }
                </Grid>
            }
        },
        // {
        //     field: 'meeting_details', headerName: 'Meeting ID', flex: 1,
        //     valueGetter: (params) => {
        //         return params.row?.queued ? 'Being Processed' : params.row.id
        //     } 
        // },
        // {
        //     field: 'meeting_password', headerName: 'Password', flex: 1,
        //     valueGetter: (params) => {
        //         return params.row?.queued ? '' : params.row.password
        //     } 
        // },
        (isCurrentMeetings && !props.isLastSemCourse && {
            field: 'actions', headerName: 'Actions', flex: 1,
            renderCell: (params) => {
                let {row} = params;
                return <MeetingActions isTeaching = {props.course.isTeaching} activeTab = {props.activeTab} meetingDetails = {row} onEnableGuestSpeaker = {props.onEnableGuestSpeaker} onJoinBeforeHost = {props.onJoinBeforeHost} onAddAltHost = {props.onAddAltHost} openSnackbar = {props.openSnackbar} onDeleteMeeting = {props.onDeleteMeeting}/>
            }
        })
    ]
}